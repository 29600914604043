
import { Vue, Component, Prop } from 'vue-property-decorator';
import vClickOutside from 'v-click-outside';

@Component
export default class BaseInputPassword extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: '' }) description!: string;
  @Prop({ default: '' }) error!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isDisabled!: boolean;

  isShowPassword = false;
  isInputClicked = false;

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.$emit('input', target.value);
  }
  checkField(): void {
    if (this.isInputClicked) {
      this.$emit('checkField');
      this.isInputClicked = false;
    }
  }

  get passwordType(): string {
    return this.isShowPassword ? 'text' : 'password';
  }

  created(): void {
    Vue.use(vClickOutside);
  }

  mounted(): void {
    this.$nextTick(() => {
      const inputRef = this.$refs.password as HTMLInputElement;
      inputRef.focus();
    });
  }
}
