import { render, staticRenderFns } from "./BaseInputPassword.vue?vue&type=template&id=2ab470ba&scoped=true"
import script from "./BaseInputPassword.vue?vue&type=script&lang=ts"
export * from "./BaseInputPassword.vue?vue&type=script&lang=ts"
import style0 from "./BaseInputPassword.vue?vue&type=style&index=0&id=2ab470ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ab470ba",
  null
  
)

export default component.exports